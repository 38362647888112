import * as React from "react"
import Button from "../components/button"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Project = ({ project }) => (
  <Link className="feature--project link image-hover pointer-none" to={'/project/'+project.slug}>
    <div
      className="h-100vh bg-grey pos-rel flex flex-wrap light overflow-hidden image-zoom"
      data-sal
      data-sal-delay="500"
    >
      <div className="content pos-rel z-2 p40 w-100 mta">
        <p className="m0 h4 mt40">Featured Project</p>
        <p className="h1 m0 mb40">{project.title}</p>
        <Button className='hide' text="Read More" />
      </div>
      <div className=" mta w-100 pos-rel z-2 p40">
        <div className="bt1 b-white pt20 flex medium">
          <p className="m0">Builder: {project.builder} <span className='m-show'>{project.category}</span></p>
          <p className="m0 mla mra m-hide">{project.category}</p>
          <p className="m0 m-mla">Completed {project.completed}</p>
        </div>
      </div>
      <GatsbyImage
        className="bg-image"
        image={project.featureImage?.gatsbyImageData}
        alt="Feature Image"
      />
    </div>
  </Link>
)

export default Project
