import * as React from "react"
import Post from "../components/post"
import { Link } from "gatsby"

const Journal = ({ journal }) => {
  return (
    <div className="journal p40 mt40 mb40">
      <div className="flex">
        <p className="m0 h4 mb40">Journal</p>
        <Link to="/journal" className="m0 mla link medium">
          See All Articles <span className="arrow ml20" />
        </Link>
      </div>
      <div className="grid">
        {journal.map((journal, index) => {
          return (
            <Post key={index} journal={journal} />
          )
        })}
      </div>
    </div>
  )
}

export default Journal
