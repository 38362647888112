import React from "react"
import Button from "../components/button"

const Capabilities = ({ content, capabilities }) => {
  return (
    <div className='capabilities p40 flex mt40 m-mt40 mb40 m-wrap'>
      <div className='w-50 m-100 fade-in m-mb40' data-sal data-sal-delay='500'>
        <p className='h4 m0 m-mb40'>Capabilities</p>
        <div className='large mt20 mb80 m-mb40 max-400'>{content}</div>
        <Button text='Read More' className='hide' link='/capabilities' />
      </div>
      <div className='w-50 m-100 m-mt40'>
        <div className='max-600'>
          {capabilities.map((capability, index) => {
            return (
              <div key={index} aa={index} data-sal data-sal-delay='700' className={" b-grey fade-in pb40 " + (index === 0 ? " m-mt40 m-bt1 m-pt40 " : "pt40 ") + (index === capabilities.length - 1 ? "" : "bb1")}>
                <p className='m0 op-50 m-op100 m-h4 mb20'>{capability.title}</p>
                <p className='m0 large m-op50 max-400'>{capability.content}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Capabilities
