import React, { useEffect } from "react"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const MoreProjects = ({ projects, title, bottomBar }) => {
  const projectHover = (category, completed) => {
    if (document.querySelector(".project--category")) {
      document.querySelector(".project--category").innerHTML = category
      document.querySelector(".completed--date").innerHTML = "Completed " + completed
    }
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      const imageWrappers = document.querySelectorAll(".project--preview-mobile")
      const positions = ["right", "left", "center"]
      let counter = 0
      imageWrappers.forEach(wrapper => {
        const image = wrapper.querySelector(".project--preview-mobile-image")
        image.classList.add(`${positions[counter]}`)
        if (counter === positions.length - 1) {
          counter = 0
        } else {
          counter += 1
        }
      })
    }
  }, [])
  return (
    <div className='more--projects p40 pos-rel overflow-hidden'>
      <p className='m0 h4 mb20 mt40'>{title}</p>
      {projects.map((project, index) => {
        return (
          <>
            <div key={index} className='project--preview fade-in m-hide' role='presentation' data-sal data-sal-delay={index * "150"} onMouseOver={() => projectHover(project.category, project.completed)} onFocus={projectHover}>
              <p to={"/project/" + project.slug} className='h1 m0 link'>
                <span className='pos-rel z-2'>{project.title}</span>
                <div className='featured--preview w-50'>
                  <div className='inner ratio-2-1 bg-grey pos-rel'>
                    <GatsbyImage className='bg-image' image={project.featureImage?.gatsbyImageData} alt={project.title} />
                  </div>
                </div>
              </p>
            </div>
            <div key={index} className='project--preview-mobile fade-in pos-rel flex justify-center align-center m-show' role='presentation' data-sal data-sal-delay={index * "150"}>
              <p to={"/project/" + project.slug} className='h1 m0 link'>
                <span className='pos-rel z-2 text-center w-100'>{project.title}</span>
                <div className='project--preview-mobile-image w-60'>
                  <div className='inner bg-grey pos-rel'>
                    <GatsbyImage className='bg-image' image={project.featureImage?.gatsbyImageData} alt={project.title} />
                  </div>
                </div>
              </p>
            </div>
          </>
        )
      })}
      <Spacer className='m-hide' />
      {bottomBar ? <Bottom /> : ""}
    </div>
  )
}
export default MoreProjects

const Bottom = () => {
  return (
    <>
      <div className=' bt1 b-grey pt20 flex medium mb40 m-mb0 fade-in pos-rel z-2 m-mt40' data-sal data-sal-delay='750'>
        <Link to='/projects' className='link hide'>
          See All Projects <span className='arrow ml20' />
        </Link>
        <p className='mla mra m0 op-50'>
          <span className='project--category'></span>
        </p>
        <p className='m0 op-50 completed--date'></p>
      </div>
    </>
  )
}
