import * as React from "react"
import Button from "../components/button"

const About = ({ title, content, link }) => (
  <div className="about p40 flex mt40 m-mt20 mb40 m-wrap">
    <div className="w-50 m-100">
      <p className="m0 h4 fade-in m-mb40" data-sal data-sal-delay="300">
        {title}
      </p>
    </div>
    <div className="w-50 m-100 fade-in" data-sal data-sal-delay="500">
      <div className="max-500 large mb40">{content}</div>
      <Button className='hide' text="Read More" link={link} />
    </div>
  </div>
)

export default About
