import * as React from "react"
import Spacer from "../components/spacer"

const Quote = ({ content, author }) => (
  <div className="quote p40 bg-orange pb0 pt0">
    <Spacer />
    <div className="h4 max-800 fade-in" data-sal data-sal-delay="500">
      {content}
    </div>
    <p className="m0 mt20 medium fade-in" data-sal data-sal-delay="700">
      {author}
    </p>
    <Spacer />
  </div>
)

export default Quote
